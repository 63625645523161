import React from 'react';
import image from './1.jpg'
import './WelcomePage.css';

const WelcomePage = () => {
  return (
    <div className="welcome-container">
      <img src={image} alt="Tanya Zhong's Avatar" className="avatar" />
      <h1>Welcome to Tanya Zhong's Website</h1>
      <p>Hi, I'm Tanya Zhong, a proud transgender woman currently studying a Master of Information Technology at UNSW. Starting in 2025, I will also be pursuing a Master of Nursing at the University of Sydney.</p>
      <p>Feel free to connect with me through the following platforms or reach out via email!</p>
      
      <div className="links">
        <a href="https://twitter.com/tanyazmx_0702" target="_blank" rel="noopener noreferrer" className="link-button twitter">Twitter</a>
        <a href="https://t.me/tanyazmx_0702" target="_blank" rel="noopener noreferrer" className="link-button telegram">Telegram</a>
        <a href="https://instagram.com/tanyazmx_0702" target="_blank" rel="noopener noreferrer" className="link-button instagram">Instagram</a>
        <a href="mailto:herself@mengxin.fyi" className="link-button email">Email Me</a>
      </div>
    </div>
  );
};

export default WelcomePage;
